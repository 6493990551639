import React, { useState, useMemo, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { CpButton, CpLoader, CpOverlay } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { tw } from "src/tailwind";
import { Selection } from "../contact-list.component";
import useContactsListQuery from "../use-contacts-list-query.hook";
import {
  MergeConflictModal,
  MergeConflictModalReturnData,
} from "./merge-conflict-modal/merge-conflict-modal.component";
import MergeModalWell from "./merge-modal-well.component";
import ContactSelectTiles from "./contact-select-tiles.component";
import { TContact } from "src/common/types";
import { NoMergePossibleModal } from "./merge-conflict-modal/no-merge-possible-modal.component";
import { ConfirmMerge } from "./confirm-merge.component";
import { Instructions } from "./instructions.component";
import { mergeContacts } from "src/resources/contacts.resource";
import { handleError } from "src/error";
import { clientQueries, contactQueries } from "src/queries";

export type MergeContactsStep = "selectMainContact" | "confirmMerge";
type MergeContactsProps = {
  selection: Selection;
  onClose: () => void;
  selectedContacts?: TContact[];
};

export function MergeContacts({ selection, onClose, selectedContacts }: MergeContactsProps) {
  const [show, setShow] = useState(true);
  const [step, setStep] = useState<MergeContactsStep>("selectMainContact");
  const closeOverlay = () => setShow(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [showNoMergePossibleModal, setShowNoMergePossibleModal] = useState(false);
  const [contactsToMerge, setContactsToMerge] = useState<TContact[]>([]);

  const contactIdsToMerge = useMemo(() => contactsToMerge.map((contact) => contact.id), [contactsToMerge]);
  const selectedContactIds = useMemo(
    () => selectedContacts?.map((contact) => contact.id) || selection.toArray(),
    [selectedContacts, selection]
  );

  const { contacts, contactsQuery } = useContactsListQuery({
    page: 1,
    limit: "10",
    infiniteScroll: false,
    filters: { id: [{ values: selectedContactIds }] },
    sortData: [],
    search: "",
    visibleFields: ["cp_user_invited", "qbo_connected", "primary_email", "primary_phone", "primary_address", "name"],
    disabled: false,
  });
  const loading = contactsQuery.isLoading;

  const contactsWithCPLogin = useMemo(() => contacts.filter((contact) => contact.cp_user_invited), [contacts]);
  const contactsWithQBOConnected = useMemo(() => contacts.filter((contact) => contact.qbo_connected), [contacts]);

  const formMethods = useForm();
  const { handleSubmit, watch } = formMethods;
  const selectedContactId = watch("mainContact");
  const disableContinue = !selectedContactId;

  useEffect(() => {
    if (loading) return;
    const hasCpLoginConflict = contactsWithCPLogin.length > 1;
    const hasQBOConflict = contactsWithQBOConnected.length > 1;
    if (contactsWithCPLogin.length === contacts.length || contactsWithQBOConnected.length === contacts.length) {
      setShowNoMergePossibleModal(true);
    } else if (hasCpLoginConflict || hasQBOConflict) {
      setShowConflictModal(true);
    } else {
      setContactsToMerge(contacts);
    }
  }, [contactsWithCPLogin, contactsWithQBOConnected, contacts, loading]);

  const onCloseConflictModal = (returnData?: MergeConflictModalReturnData) => {
    setShowConflictModal(false);
    if (returnData) {
      const { contactIdsToExclude } = returnData;
      const filteredContacts = contacts.filter((contact) => !contactIdsToExclude.includes(contact.id));
      setContactsToMerge(filteredContacts);
    } else {
      closeOverlay();
    }
  };
  const onContinue = handleSubmit(({ mainContact }) => {
    if (disableContinue || !mainContact) return;
    setStep("confirmMerge");
  });
  const onBack = () => {
    setStep("selectMainContact");
  };
  const onConfirmMerge = handleSubmit(async ({ mainContact }) => {
    const mergedContactsCount = contactIdsToMerge.length;
    const mainContactName = contacts.find((contact) => contact.id === mainContact)?.name;
    if (disableContinue || !mainContact) return;
    try {
      await mergeContacts({
        main_contact_id: mainContact,
        contact_ids: contactIdsToMerge.filter((id) => id !== mainContact),
        action: "confirm",
      });
      selection.deselectAll();
      contactQueries.invalidate();
      clientQueries.invalidate();
      closeOverlay();
      successToast(`${mergedContactsCount} contacts merged into ${mainContactName}`);
    } catch (error) {
      handleError(error);
    }
  });

  return (
    <CpOverlay show={show} onClose={closeOverlay} onAfterClose={onClose}>
      <CpOverlay.Header title="Merge Contacts">
        {step === "selectMainContact" ? (
          <CpButton btnType="primary" onClick={onContinue} disabled={disableContinue}>
            Continue
          </CpButton>
        ) : (
          <div className={tw("flex flex-row gap-4")}>
            <CpButton btnType="secondary" onClick={onBack}>
              Back
            </CpButton>
            <CpButton btnType="primary" onClick={onConfirmMerge}>
              Confirm merge
            </CpButton>
          </div>
        )}
      </CpOverlay.Header>
      <CpOverlay.Body>
        <div className={tw("flex flex-row gap-10 py-2 px-4")}>
          <Instructions step={step} />
          <MergeModalWell
            title={step === "selectMainContact" ? "Select Main Contact" : "Confirm Merged Contact"}
            className={tw("flex-1")}
          >
            {loading && !contactsToMerge?.length ? (
              <CpLoader size="lg" />
            ) : step === "selectMainContact" ? (
              <FormProvider {...formMethods}>
                <ContactSelectTiles contacts={contactsToMerge} />
              </FormProvider>
            ) : (
              <ConfirmMerge mainContactId={selectedContactId} contactIds={contactIdsToMerge} />
            )}
          </MergeModalWell>
        </div>
      </CpOverlay.Body>
      {showConflictModal && (
        <MergeConflictModal
          cpConflictContacts={contactsWithCPLogin}
          qboConflictContacts={contactsWithQBOConnected}
          onClose={onCloseConflictModal}
        />
      )}
      {showNoMergePossibleModal && <NoMergePossibleModal onClose={closeOverlay} />}
    </CpOverlay>
  );
}
