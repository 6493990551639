import React, { useState } from "react";
import { CpButton, CpModal } from "canopy-styleguide!sofe";
import { TContact } from "src/common/types";
import { tw } from "src/tailwind";
import { ContactRadioWell } from "./contact-radio-well.component";
import { useForm, FormProvider } from "react-hook-form";
import { CP_CONFLICT_MESSAGE, QBO_CONFLICT_MESSAGE } from "../merge-contacts.helpers";

export type MergeConflictModalReturnData = {
  contactIdsToExclude: string[];
};

type MergeConflictModalProps = {
  cpConflictContacts: TContact[];
  qboConflictContacts: TContact[];
  onClose: (returnData?: MergeConflictModalReturnData) => void;
};

export function MergeConflictModal({ cpConflictContacts, qboConflictContacts, onClose }: MergeConflictModalProps) {
  const [show, setShow] = useState(true);
  const [showError, setShowError] = useState(false);
  const closeModal = () => setShow(false);
  const onCancel = () => closeModal();

  const [hasCPConflict, setHasCPConflict] = useState(cpConflictContacts.length > 1);
  const [resolvedCpExclusions, setResolvedCpExclusions] = useState<string[]>([]);
  const hasQboConflict = qboConflictContacts.length > 1;

  const [conflictsToResolve, setConflictsToResolve] = useState<TContact[]>(
    cpConflictContacts.length > 1 ? cpConflictContacts : qboConflictContacts
  );

  // always resolve the cp conflict first
  const conflictType = hasCPConflict ? "cp" : "qbo";

  const formMethods = useForm();
  const { handleSubmit } = formMethods;

  /**
   * Given a list of contacts and the selected contact ID,
   * returns the IDs of contacts that are *not* selected.
   */
  const getExclusions = (contacts: TContact[], selectedId: string) =>
    contacts.filter((contact) => contact.id !== selectedId).map((contact) => contact.id);

  /**
   * Handles CP conflict resolution.
   * 1. Excludes the CP contacts not selected.
   * 2. If a QBO conflict exists, checks whether there are unresolved QBO contacts.
   *    - If so, stores the CP exclusions and updates state to resolve QBO next.
   * 3. Otherwise, finalizes by closing the modal with CP exclusions.
   */
  const handleCpContinue = (selectedCpId: string) => {
    const cpExclusions = getExclusions(cpConflictContacts, selectedCpId);

    if (hasQboConflict) {
      // Filter out any QBO contacts that were already excluded during CP conflict resolution.
      const unresolvedQboContacts = qboConflictContacts.filter((contact) => !cpExclusions.includes(contact.id));

      // Check if all remaining contacts are QBO contacts
      const selectedContact = cpConflictContacts.find((contact) => contact.id === selectedCpId);
      const hasOnlyQboContacts =
        selectedContact?.qbo_connected && unresolvedQboContacts.every((contact) => contact.qbo_connected);

      if (hasOnlyQboContacts) {
        setShowError(true);
        return;
      }

      if (unresolvedQboContacts.length > 1) {
        setResolvedCpExclusions(cpExclusions);
        setConflictsToResolve(unresolvedQboContacts);
        setHasCPConflict(false);
        return; // QBO conflict will be resolved next.
      }
    }

    // No further conflicts—finalize with CP exclusions.
    onClose({ contactIdsToExclude: cpExclusions });
  };

  /**
   * Handles QBO conflict resolution.
   * Excludes the QBO contacts that weren't selected, and returns that with the resolved CP exclusions.
   */
  const handleQboContinue = (selectedQboId: string) => {
    const qboExclusions = getExclusions(qboConflictContacts, selectedQboId);
    onClose({ contactIdsToExclude: [...resolvedCpExclusions, ...qboExclusions] });
  };

  const onContinue = handleSubmit((formData) => {
    if (hasCPConflict) {
      handleCpContinue(formData.cp);
    } else {
      handleQboContinue(formData.qbo);
    }
  });

  return (
    <CpModal show={show} onClose={closeModal} onAfterClose={onClose} width={600}>
      <CpModal.Header title="Merge Conflicts Detected" />
      <CpModal.Body className={`cp-body ${tw("flex flex-col gap-4")}`}>
        {showError ? (
          <div>No available contacts remain for merging. Please try your selection again.</div>
        ) : (
          <>
            <div>{conflictType === "cp" ? CP_CONFLICT_MESSAGE : QBO_CONFLICT_MESSAGE}</div>
            <FormProvider {...formMethods}>
              <ContactRadioWell contacts={conflictsToResolve} conflictType={conflictType} />
            </FormProvider>
          </>
        )}
      </CpModal.Body>
      <CpModal.Footer className={tw("flex gap-2")}>
        {showError ? (
          <>
            <CpButton btnType="primary" onClick={() => setShowError(false)}>
              Select contacts
            </CpButton>
            <CpButton btnType="flat" onClick={onCancel}>
              Cancel
            </CpButton>
          </>
        ) : (
          <>
            <CpButton btnType="primary" onClick={onContinue}>
              Continue
            </CpButton>
            <CpButton btnType="flat" onClick={onCancel}>
              Cancel
            </CpButton>
          </>
        )}
      </CpModal.Footer>
    </CpModal>
  );
}
