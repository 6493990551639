import * as React from "react";
import CprMask from "cpr-mask";
import { InputDecorator } from "src/forms-lib";

type Props = {
  updateValue: (string: string) => void;
  value: string;
  placeholder?: string;
  masks: Array<{ condition: (value: string) => boolean; pattern: string }>;
  inputProps: any;
  nonValidMsg?: string;
  validationFn: (string: string) => boolean;
  showInvalid: boolean;
  filler?: string;
  className?: string;
};

export class MaskedInput extends React.Component<Props> {
  maskedInput?: any;

  static defaultProps = {
    validationFn: () => true,
  };

  render() {
    return (
      <CprMask
        className={this.props.className}
        filler={this.props.filler}
        initialValue={this.props.value}
        onChange={this.handleChange}
        inputClass="cps-form-control"
        inputProps={this.props.inputProps}
        validateMethod={this.props.validationFn}
        nonValidMsg={this.props.nonValidMsg}
        invalidClass={"cps-has-error"}
        masks={this.props.masks}
        ref={(el: HTMLElement) => (this.maskedInput = el)}
      />
    );
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.showInvalid === true) {
      // this is so wrong... I need a way to force the CPRMask component to trigger a dirty state
      // I can either fake a blur event or update the isDirty state.
      if (this.maskedInput && this.maskedInput.state.isDirty === false) {
        this.maskedInput.setState({ isDirty: true });
      }
    }
  }

  handleChange = (value: string) => {
    this.props.updateValue(value);
  };

  focus = () => {
    if (this.maskedInput) {
      this.maskedInput.input && this.maskedInput.input.focus();
    }
  };
}

export default InputDecorator(MaskedInput);
