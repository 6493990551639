import * as React from "react";
import { Scoped } from "kremling";
// project
import styles from "./create-edit-client-form.krem.css";
import FormBlock from "src/form/form-block/form-block.component";
import { Form } from "src/forms-lib";
import BusinessSection from "./business-section/business-section.component.js";
import ClientInformationSection from "./client-information/client-information.component.js";
import TypeAndLongevity from "./type-and-longevity/type-and-longevity.component.js";
import ActiveClientToggle from "./active-client-section/active-client.component.js";
import PhoneNumbersSection from "./phone-numbers-section/phone-numbers-section.component.js";
import EmailSection from "./email-section/email-section.component.js";
import AddressSection from "./address-section/address-section.component.js";
import DetailedInformationSection from "./detailed-information-section/detailed-information-section.component";
import TagsSection from "./tags-section/tags-section.component";
import RolesSectionForCreateEditModal from "./roles-section/roles-section-for-create-edit-modal.component";
import ClientOwner from "./client-owner/client-owner.component.js";
import ContactPerson from "./contact-person/contact-person.component";
import Source from "./source-and-note-section/source/source.component.js";
import Note from "./source-and-note-section/note/note.component.js";
import CustomFieldsSection from "./custom-fields-section/custom-fields-section.component";
import ExternalId from "./external-id/external-id.component.js";
import ClientFilingStatus from "./client-filing-status/client-filing-status.component.js";
import UpgradeClientsModal from "src/modal/upgrade-clients-modal/upgrade-clients-modal.component";
import { hasAccess, useWithUserAndTenant } from "cp-client-auth!sofe";
import { SelectTemplateSection } from "src/create-edit-client-old/create-edit-client-form/select-template-section.component";

export default React.forwardRef((props, ref) => {
  const [loggedInUser] = useWithUserAndTenant();
  return <CreateEditClientForm loggedInUser={loggedInUser} {...props} forwardedRef={ref} />;
});

class CreateEditClientForm extends React.Component {
  state = {
    activeClient: false,
    showClientUpgradeModel: false,
  };

  componentDidMount() {
    const client = this.props.client || getDevClient();
    this.setState({
      activeClient: client
        ? client.is_active
        : this.props.isClientLimitModel
          ? !this.props.clientsUsageData.reached_limit
          : true,
    });
  }

  handleActiveToggle = (active) => {
    const { client, isClientLimitModel, clientsUsageData } = this.props;
    if ((!client || !client.is_active) && active && isClientLimitModel && clientsUsageData.reached_limit) {
      this.setState({ showClientUpgradeModel: true });
    } else {
      this.setState({ activeClient: active });
    }
  };

  render() {
    const client = this.props.client || getDevClient();
    const { forwardedRef, isClientLimitModel, clientsUsageData } = this.props;
    const hasClientsPermission = hasAccess(this.props.loggedInUser)("clients");
    const canApplyTemplates = hasAccess(this.props.loggedInUser)("templates_client_records_apply");

    return this.state.showClientUpgradeModel ? (
      <UpgradeClientsModal close={this.props.closeModal}>
        <span>
          You've hit your maximum of <span className="cps-wt-semibold">{clientsUsageData.allowed} clients</span> for
          your pricing tier.
        </span>
      </UpgradeClientsModal>
    ) : (
      <Form
        id="create_edit_client_form"
        onSubmit={this.onFormSubmit}
        initialValue={client}
        preservedValues={["id", "is_business", "is_hidden"]}
        sharedValues={[
          "is_business",
          "business_type",
          "industry",
          "client_type",
          "is_active",
          "filing_status",
          "client_owner",
          "custom_fields",
          "roles_fields",
          "users_teams_field",
          "tags",
        ]}
        ref={forwardedRef}
      >
        <Scoped css={styles}>
          <div className="cp-mv-32">
            {canApplyTemplates && (
              <div className="template-section">
                <SelectTemplateSection
                  isBusiness={this.props.isEdit ? client?.is_business || false : null}
                  isEdit={this.props.isEdit}
                />
              </div>
            )}
            {!this.props.isEdit && !this.props.hideBusinessChooser && <BusinessSection />}
            <FormBlock>
              <div className="cp-body-sm cp-wt-semibold cp-mb-16">General Info</div>
              <ClientInformationSection sharedKey="is_business" alwaysShow={true} />
              {hasClientsPermission && (
                <>
                  <TypeAndLongevity />
                  <ActiveClientToggle
                    name="is_active"
                    initialValue={isClientLimitModel ? !clientsUsageData.reached_limit : true}
                    onToggle={this.handleActiveToggle}
                  />
                </>
              )}
            </FormBlock>
            <FormBlock>
              <div className="cp-body-sm cp-wt-semibold cp-mb-16">Client Details</div>
              <EmailSection />
              <PhoneNumbersSection />
              <AddressSection />
            </FormBlock>
            <FormBlock showHr={hasClientsPermission}>
              <div className="cp-body-sm cp-wt-semibold cp-mb-16">Personal Info</div>
              <DetailedInformationSection sharedKey="is_business" />
              <div className="additionalInfo">
                <ClientOwner name="client_owner" />
                <ExternalId />
                <ClientFilingStatus name="filing_status" />
              </div>
            </FormBlock>
            {hasClientsPermission && (
              <FormBlock>
                <div className="cp-body-sm cp-wt-semibold cp-mb-16">Custom Fields</div>
                <CustomFieldsSection customFields={this.props.customFields} client={this.props.client} />
                <Source name="source" initialValue={{ selected: {} }} />
                <Note name="note" />
                <ContactPerson sharedKey="is_business" />
              </FormBlock>
            )}
            <FormBlock>
              <RolesSectionForCreateEditModal
                roleAssignments={client?.roleAssignments}
                usersTeams={client?.usersTeams}
                defaultUser={!this.props.isEdit && this.props.loggedInUser?.id}
              />
            </FormBlock>
            {hasClientsPermission && (
              <FormBlock showHr={false}>
                <div className="cp-body-sm cp-wt-semibold cp-mb-16">Tags</div>
                <TagsSection initialValue={[]} name="tags" />
              </FormBlock>
            )}
          </div>
        </Scoped>
      </Form>
    );
  }

  onFormSubmit = (valid, model) => {
    if (valid && !this.props.disabled) {
      this.props.onValidSubmit && this.props.onValidSubmit(model);
    }
  };
}

function getDevClient() {
  try {
    const client = localStorage.getItem("cp-dev:client");
    if (client != undefined) {
      const returnValue = JSON.parse(client);
      const birth_date = parseDate(returnValue.birth_date);
      const client_since = parseDate(returnValue.client_since);
      return { ...returnValue, birth_date, client_since };
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}

function parseDate(dateString) {
  try {
    const date = new Date(dateString);
    if (date instanceof Date && !isNaN(date)) {
      return date;
    } else {
      return undefined;
    }
  } catch (e) {
    return undefined;
  }
}
