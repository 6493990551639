import React, { ReactNode, useMemo, useState } from "react";
import { ActionItemList } from "src/common/client-contact/action-item-list";
import { ArchiveContactModal } from "./archive-contact-modal";
import { handleError } from "src/error";
import { contactQueries } from "src/queries";
import { MergeContacts } from "./merge-contacts/merge-contacts.component";
import { Selection } from "./contact-list.component";
import { featureEnabled } from "feature-toggles!sofe";
import { TContact } from "src/common/types";

// In cases where we're limiting selection counts to be less than the min page size we use this to get a list of contacts instead of dealing with multi page select all.
const getSelectedContacts = (selection: Selection, contacts: TContact[]): TContact[] => {
  if (selection.allSelected || selection.type === "excludes") {
    // If all are selected, filter out the excluded ones
    return contacts.filter((contact) => !selection.byId[contact.id]);
  } else if (selection.type === "includes") {
    // If using includes, only return contacts that are explicitly selected
    return contacts.filter((contact) => selection.byId[contact.id]);
  } else {
    return [];
  }
};

export type BulkAction = {
  id: string;
  label: string;
  icon: string;
  iconRight?: string | React.ReactNode;
  permissions: string[];
  disabledCheck?: (selection: Selection) => boolean;
  disabledLabel?: string | React.ReactNode;
  minSelection?: number;
  maxSelection?: number;
  maxSelectionLabel?: string | React.ReactNode;
  execute?: (props: { search: string; filters: any; selection: Selection; selectedContacts?: TContact[] }) => void;
  renderModal?: (props: {
    search: string;
    filters: any;
    selection: Selection;
    // selectedContacts is only populated if the action has a maxSelection less than the min page size
    selectedContacts?: TContact[];
    closeModal: () => void;
  }) => ReactNode;
};
const bulkActions: BulkAction[] = [
  {
    id: "email",
    label: "Email",
    icon: "communication-envelope",
    permissions: ["clients_bulk_email"],
    execute: ({ search, filters, selection }: { search: string; filters: any; selection: Selection }) => {
      const params = {
        crm_type: "contacts",
        v2Filters: { [selection.type]: selection.toArray(), filters, search },
      };
      SystemJS.import("communications-ui!sofe")
        .then((communicationsUI: any) => {
          communicationsUI.sendBulkEmail(params).subscribe((emailAction: any) => {
            if (emailAction.updatedContacts) {
              contactQueries.invalidate();
            }
          }, handleError);
        })
        .catch(handleError);
    },
  },
  ...(featureEnabled("toggle_merge_contacts")
    ? [
        {
          id: "merge",
          label: "Merge contacts",
          icon: "af-merge",
          permissions: ["clients_delete", "clients_create_edit"],
          minSelection: 2,
          maxSelection: 10,
          maxSelectionLabel: "Unable to merge more than 10 contacts",
          renderModal: ({
            selection,
            closeModal,
            selectedContacts,
          }: {
            selection: Selection;
            closeModal: () => void;
            selectedContacts?: TContact[];
          }) => {
            return <MergeContacts selection={selection} onClose={closeModal} selectedContacts={selectedContacts} />;
          },
        },
      ]
    : []),
  {
    id: "archive",
    label: "Archive",
    icon: "crud-archive",
    permissions: ["contacts_archive"],
    renderModal: ({
      search,
      filters,
      selection,
      closeModal,
    }: {
      search: string;
      filters: any;
      selection: Selection;
      closeModal: () => void;
    }) => {
      return <ArchiveContactModal search={search} filters={filters} selection={selection} onAfterClose={closeModal} />;
    },
  },
];

type BulkActionsProps = {
  allowedActions?: string[];
  filters: any;
  selection: Selection;
  search: string;
  contacts: TContact[];
};

export function BulkActions({ allowedActions, filters, search, selection, contacts }: BulkActionsProps) {
  const filteredActions = useMemo(() => {
    return bulkActions.filter((actionItem) => {
      const hasMinSelection = !!actionItem.minSelection ? selection.totalSelected >= actionItem.minSelection : true;
      const isAllowed = !!allowedActions ? allowedActions?.includes(actionItem.id) : true;
      return isAllowed && hasMinSelection;
    });
  }, [allowedActions, selection]);
  const [renderModal, setRenderModal] = useState<() => ReactNode>();
  return (
    <>
      <ActionItemList
        actionItems={filteredActions}
        onActionClick={(actionItem) => {
          const selectedContacts =
            actionItem.maxSelection && actionItem.maxSelection <= 50 && selection.type === "excludes"
              ? getSelectedContacts(selection, contacts)
              : undefined;

          if (actionItem.execute) {
            actionItem.execute({ search, filters, selection, selectedContacts });
          }

          if (actionItem.renderModal) {
            setRenderModal(
              () => () =>
                actionItem.renderModal?.({
                  search,
                  filters,
                  selection,
                  selectedContacts,
                  closeModal: () => setRenderModal(undefined),
                })
            );
          }
        }}
        selection={selection}
      />
      {renderModal?.()}
    </>
  );
}
