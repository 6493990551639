import React, { useState } from "react";
import { CpModal, CpButton } from "canopy-styleguide!sofe";
import { successToast } from "toast-service!sofe";
import { TContact } from "src/common/types";
import { patchContacts, updateContact } from "src/resources/contacts.resource";
import { contactQueries } from "src/queries";
import { handleError } from "src/error";
import { Selection } from "./contact-list.component";

type ArchiveContactModalProps = { onAfterClose: () => void } & ProvidedContact;

type ProvidedContact =
  | {
      contact: TContact;
      selection?: Selection;
      filters?: never;
      search?: never;
    }
  | {
      selection: Selection;
      filters: any;
      search: string;
      contact?: never;
    };

export function ArchiveContactModal({ contact, selection, filters, search, onAfterClose }: ArchiveContactModalProps) {
  const [show, setShow] = useState(true);
  const close = () => setShow(false);
  const onArchive = async () => {
    if (!!contact) {
      try {
        await updateContact(contact.id, { is_archived: true });
        if (selection?.isSelected?.(contact.id)) {
          selection.toggleSelection(contact.id);
        }
        contactQueries.invalidate();
        successToast(`Successfully archived ${contact.name}.`);
      } catch (e) {
        handleError(e);
      }
    } else {
      const count = selection.totalSelected;
      try {
        await patchContacts({
          search,
          filters,
          action: "archive",
          [selection.type]: selection.toArray(),
        });
        selection.deselectAll();
        successToast(`${count} contact${count === 1 ? "" : "s"} will be archived. This may take some time.`);
        // Try to give the bulk action some time before invalidating
        setTimeout(() => contactQueries.invalidate(), 2000);
      } catch (e) {
        handleError(e);
      }
    }
    close();
  };
  return (
    <CpModal show={show} onClose={close} onAfterClose={onAfterClose}>
      <CpModal.Header title="Archive Contact" />
      <CpModal.Body>
        <p>
          {!!contact
            ? `You are archiving ${contact.name} as a contact. This will remove them from their assigned client records and remove their client portal access.`
            : `The selected contacts will be archived. Archiving contacts will remove them from their assigned client records and remove their client portal access. Any selected contacts who are assigned to a client record as a primary contact or are involved in the QBO sync will not be archived.`}
        </p>
        <p>Visit the Archived Contacts page to unarchive contacts.</p>
      </CpModal.Body>
      <CpModal.Footer className="flex cp-gap-16">
        <CpButton onClick={onArchive}>Continue</CpButton>
        <CpButton btnType="flat" onClick={close}>
          Cancel
        </CpButton>
      </CpModal.Footer>
    </CpModal>
  );
}
