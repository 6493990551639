import { capitalize } from "lodash";
import { phoneNumber } from "canopy-styleguide!sofe";
import { formatSsn } from "src/common/utils.helpers";
import { TAddress, TAddressValue, TAssignedClient, TContact, TEmail, TPhone } from "src/common/types";
import { DateTime } from "luxon";

export type TFieldData = {
  label: string;
  value: string;
};

export type TAddressFieldData = {
  label: string;
  value: TAddressValue;
};

export type TContactInfo =
  | {
      firstName: TFieldData;
      lastName: TFieldData;
      middleName: TFieldData;
      emails: TFieldData[];
      phones: TFieldData[];
      addresses: TAddressFieldData[];
      birthdate: TFieldData;
      tin: TFieldData;
      occupation: TFieldData;
      employer: TFieldData;
    }
  | Record<string, never>;

export type TClientPortalInfo =
  | {
      clientPortalEmail: string;
      hasClientPortalAccess: boolean;
    }
  | Record<string, never>;

type TViewContactSections = {
  contactInfo: TContactInfo;
  clientPortalInfo: TClientPortalInfo;
  assignedClients: TAssignedClient[];
};

export function mapContactDataToSections(contact?: Omit<TContact, "id">): TViewContactSections {
  if (!contact) {
    return {
      contactInfo: {},
      clientPortalInfo: {},
      assignedClients: [],
    };
  }
  const { addresses, birthdate, client_portal_email, clients, emails, employer, occupation, phones, tin } = contact;

  return {
    contactInfo: {
      firstName: {
        label: "First name",
        value: contact.first_name ?? "—",
      },
      middleName: {
        label: "Middle name",
        value: contact.middle_name ?? "—",
      },
      lastName: {
        label: "Last name",
        value: contact.last_name ?? "—",
      },
      emails:
        emails?.sort(primaryFirst).map((email) => ({
          label: `${email.is_primary ? "Primary" : capitalize(email.key)} email`,
          value: email.value,
        })) ?? [],
      phones:
        phones?.sort(primaryFirst).map((phone) => ({
          label: `${phone.is_primary ? "Primary" : capitalize(phone.key)} phone`,
          value: phone.value ? (phoneNumber(phone.value) as string) : "",
        })) ?? [],
      addresses:
        addresses?.sort(primaryFirst).map((address) => ({
          label: `${address.is_primary ? "Primary" : capitalize(address.key)} address`,
          value: address.value,
        })) ?? [],
      birthdate: {
        label: "Birthday",
        value: birthdate ? DateTime.fromISO(birthdate).toLocaleString(DateTime.DATE_MED) : "",
      },
      tin: {
        label: "SSN",
        value: formatSsn(tin ?? ""),
      },
      occupation: {
        label: "Occupation",
        value: occupation ?? "",
      },
      employer: {
        label: "Employer",
        value: employer ?? "",
      },
    },
    clientPortalInfo: {
      clientPortalEmail: client_portal_email || "",
      hasClientPortalAccess: clients?.some((client) => !!client.latest_invite_sent) ?? false,
    },
    assignedClients: clients || [],
  };
}

function primaryFirst(a: TPhone | TEmail | TAddress, b: TPhone | TEmail | TAddress) {
  return a.is_primary === b.is_primary ? 0 : a.is_primary ? -1 : 1;
}
