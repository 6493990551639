import { useQuery, useQueryClient } from "src/react-query";
import { patchUser } from "src/resources/users.resource.js";
import { handleError } from "src/error";
import { useWithUserAndTenant } from "cp-client-auth!sofe";

/*
 * Here we're using react-query to manage the state of user preferences. Since we are typically working with user objects we can avoid BE calls to get preferences for a user by passing in the user data to this hook.
 * So user data should be passed into this query, and in order to update components that use this query you can use `queryClient.setQueryData(['user-preferences', $UPDATED_USER.id], $UPDATED_USER.preferences)`
 */
export default function useUserPrefsQuery() {
  const queryClient = useQueryClient();
  const [user] = useWithUserAndTenant();

  function updateUserPrefs(prefs) {
    patchUser(user.id, {
      preferences: {
        ...user.preferences,
        ...prefs,
      },
    }).subscribe((updatedUser) => {
      queryClient.setQueryData(["user-preferences", updatedUser.id], updatedUser.preferences);
    }, handleError);
  }
  const query = useQuery({
    queryKey: ["user-preferences", user?.id],
    queryFn: () =>
      new Promise((resolve, reject) => {
        resolve(user?.preferences || null);
      }),
    gcTime: Infinity,
    staleTime: Infinity,
  });

  return {
    userPrefsQuery: query,
    userPrefs: query.data,
    updateUserPrefs,
  };
}
