import React, { useMemo } from "react";
import { CpButton, CpTooltip, CpFloatingMenu } from "canopy-styleguide!sofe";
import { useWithUserAndTenant, hasAccess } from "cp-client-auth!sofe";
import { BulkAction } from "src/contact-list/bulk-actions";
import { Selection } from "src/contact-list/contact-list.component";

type ActionItemListProps = {
  actionItems: BulkAction[];
  actionItemOrder?: string[];
  maxVisibleActions?: number;
  onActionClick: (actionItem: BulkAction) => void;
  selection: Selection;
};

export function ActionItemList({
  actionItems,
  actionItemOrder,
  maxVisibleActions = 6,
  onActionClick,
  selection,
}: ActionItemListProps) {
  const [user] = useWithUserAndTenant();
  const availableActions = useMemo(() => {
    return actionItems
      .filter((actionItem) => {
        return !actionItem.permissions || hasAccess(user, true)(actionItem.permissions);
      })
      .sort((a, b) => {
        if (!actionItemOrder) return 0;
        return actionItemOrder.indexOf(a.id) - actionItemOrder.indexOf(b.id);
      });
  }, [actionItems, actionItemOrder, user]);

  const buttonActions = availableActions.slice(0, maxVisibleActions);
  const kabobActions = availableActions.slice(maxVisibleActions);

  const getActionDisabledState = (action: BulkAction) => {
    if (action.maxSelection && selection.totalSelected > action.maxSelection) {
      return {
        disabled: true,
        label: action.maxSelectionLabel || `Only ${action.maxSelection} contacts can be selected for this action`,
      };
    }

    if (action.disabledCheck?.(selection)) {
      return {
        disabled: true,
        label: action.disabledLabel,
      };
    }

    return {
      disabled: false,
      label: "",
    };
  };

  if (!user) return null;
  return (
    <div>
      {buttonActions.map((action) => {
        const { disabled, label } = getActionDisabledState(action);
        return (
          <CpTooltip text={disabled ? label : action.label} key={action.id}>
            <CpButton
              btnType="icon"
              icon={action.icon}
              aria-label={action.label}
              className="cp-mr-8"
              onClick={() => onActionClick(action)}
              disabled={disabled}
            />
          </CpTooltip>
        );
      })}
      {kabobActions.length > 0 && (
        <CpFloatingMenu
          renderTrigger={(refProps: any) => (
            <CpTooltip text="More actions">
              <CpButton icon="misc-kabob" btnType="icon" aria-label="More actions" {...refProps} />
            </CpTooltip>
          )}
        >
          {kabobActions.map((action) => {
            const { disabled, label } = getActionDisabledState(action);
            return (
              <CpFloatingMenu.Item
                text={action.label}
                subtext={disabled ? label : undefined}
                iconLeft={action.icon}
                iconRight={action.iconRight}
                disabled={disabled}
                key={action.id}
                onClick={() => onActionClick(action)}
              />
            );
          })}
        </CpFloatingMenu>
      )}
    </div>
  );
}
